import './App.css';
import Menu from "./components/menu/Menu";
import Info from "./components/info/Info";
import Competence from "./components/competence/Competence";
import Pricelist from "./components/pricelist/Pricelist";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div id="main">
      {Menu()}
      {Info()}
      {Competence()}
      {Pricelist()}
      {Contact()}
      {Footer()}
    </div>
    
  );
}

export default App;
