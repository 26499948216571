import './Competence.css'
import Certificate from './certificate.jpg'
import {useTranslation,Trans} from 'react-i18next'



function Competence() {
    const{ t } = useTranslation()
    return (
        <div id="competence">
            <div id="competence-content">
                <div id="certificate">
                    <img src={Certificate} alt="certyfikat"></img>
                </div>
                <div id="competence-info">
                    <p id="kompetencje">{t('competences2')}</p>
                    <p className="text-competence"><Trans i18nKey="do1">Wykonuję tłumaczenia tekstów z dziedziny motoryzacji, edukacji, prawa, handlu, gospodarki, ekonomii, księgowości, ubezpieczeń, medycyny.</Trans></p> 
                    <p className="text-competence"><Trans i18nKey="do2">Współpracuję z firmami polskimi i niemieckimi, urzędami administracji państwowej oraz klientami indywidualnymi.</Trans></p>
                    <p className="text-competence"><Trans i18nKey="do3">Zapraszam do współpracy,</Trans></p>
                    
                    <p id="name2">Romana Barylak</p>
                    
                </div>
            </div>
        </div>
    );
  }
  
  export default Competence;
  