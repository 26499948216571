import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF} from "@react-google-maps/api";



function MyComponent() {
  const {isLoaded}= useLoadScript({
    googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  if(!isLoaded) return<div> Loading...</div>
  return <Map/>
}

function Map() {
  const center = useMemo(()=>({ lat: 50.114297, lng: 18.512266 }),[]);
  return (
    <GoogleMap   mapContainerClassName="map-container" center={center} zoom={15}>
      <MarkerF position={center} ></MarkerF>
    </GoogleMap>
  );
}

export default MyComponent;
