import React, { useState } from "react";
import "./Menu.css";
import hamburgerMenu from "./menu_hamburger.svg";
import {useTranslation} from 'react-i18next'

const lngs = { 
  pl:{nativeName:'Polski'},
  de:{nativeName:"Deutsch"},
}

function Menu() {
  const{ t,i18n } = useTranslation()
  const [isChecked, setIsChecked] = useState(false);
  const boxStyle = isChecked
    ? {
        zindex:"4",
        position:"absolute",
        top:"40px",
        height: "180px",
        width: "100%",
        display: "flex",
        transition: "height ease-in-out 1.5s, ",
      }
    : {};
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
 
  return (
    <div id="menu">
      <div id="menu-nav">
        <input
          type="checkbox"
          id="menu-hamburger-button"
          checked={isChecked}
          onChange={handleCheckboxChange}
        ></input>
        <label htmlFor="menu-hamburger-button">
          <img src={hamburgerMenu} alt="hamburgerek" id="hambruger-icon"></img>
        </label>
        <div id="navigation" style={boxStyle}>
          <a href="#competence">{t('competences')}</a>
          <a href="#pricelist">{t('pricelist')}</a>
          <a href="#contact">{t('contact')}</a>
          {/* <a>{lng}</a> */}
          {Object.keys(lngs).map((lng)=>(
             <button type="submit" key={lng}  onClick={()=>i18n.changeLanguage(lng) }hidden={i18n.resolvedLanguage===lng} id="language">{t('language')}</button>
           ))} 
           
         
        </div>
      </div>
    </div>
  );
}

export default Menu;
