import './Footer.css'
import JohnCube from './johncube.svg'



function Footer() {
    return (
        <div id="footer">
            <div id="footer-content">
                <div id="logo">
                    <img src={JohnCube} alt="JohnCube"></img>
                    <p>JohnCube<br/><span>2024</span></p>
                </div>
            </div>
            
            
        </div>
    );
  }
  
  export default Footer;