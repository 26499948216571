import './Info.css'
import Globe from './globe.jpg'
import {useTranslation,Trans} from 'react-i18next'


function Info() {
    const{ t } = useTranslation()
    return (
        <div id="info">
            <div id="info-content">
                <div id="business-card">
                    <p id="info-name"><Trans i18nKey="infoname">Tłumacz przysięgły<br/> języka niemieckiego</Trans></p>
                    <p id="name">Romana Barylak</p>
                    <a id="contact-button" href="#contact" >{t('contact')}</a>
                </div>
                <div id="globe">
                    <img src={Globe} alt="Globe"></img>
                </div>
            </div>
        </div>
    );
  }
  
  export default Info;
  