import "./Pricelist.css";
import {useTranslation,Trans} from 'react-i18next'

function Pricelist() {
  const{ t } = useTranslation()
  return (
    
    <div id="pricelist">
      <div id="pricelist-content">
        <div >
        <p id="cennik">{t("pricelist2")}</p>
        <p className="pricelist-info"><Trans i18nKey="pricelistinfo1">
          Jedna strona tłumaczenia przysięgłego to 1125 znaków ze spacjami w
          języku docelowym. Tłumaczenie zwykłe zawiera 1500 znaków. Ceny podane
          są za każdą rozpoczętą stronę tłumaczenia.</Trans>
        </p>
        </div>
        <div id="pricelist-pricelist">
          <div className="pricelist-info-content">
            <div className="item">
              <p><Trans i18nKey="pricelistitem1">tłumaczenie (strona)</Trans></p>
              <p>60&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p className="item-item">
                <Trans i18nKey="pricelistitem2">tłumaczenie (strona) <br />
                wykonanie w dniu zlecenia</Trans>
              </p>
              <p>120&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p><Trans i18nKey="pricelistitem3">sporządzenie kopii tłumaczenia</Trans></p>
              <p>12&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p className="item-item">
                <Trans i18nKey="pricelistitem4">zaświadczenie odpisu pisma <br />w języku obcym</Trans>
              </p>
              <p>18&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p className="item-item">
                <Trans i18nKey="pricelistitem5">sprawdzenie i zaświadczenie <br />
                dostarczonego tłumaczenia</Trans>
              </p>
              <p>30&nbsp;zł</p>
            </div>
          </div>
          <div className="pricelist-info-content" >
            <div className="item">
              <p className="item-item">
                <Trans i18nKey="pricelistitem6">tłumaczenie ustne
                <br />
                (za każdą rozpoczętą godzinę)</Trans>
              </p>
              <p>300&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p className="item-item"><Trans i18nKey="pricelistitem7">dokumenty samochodowe</Trans></p>
              <p>{t("from")}&nbsp;150&nbsp;zł</p>
            </div>
            <hr />
            <div className="item">
              <p className="item-item">
                <Trans i18nKey="pricelistitem8">tłumaczenie tekstu sporządzonego pismem odręcznym lub z trudnej
                do odczytania kopii lub zawierającego frazeologię i terminologię
                specjalistyczną</Trans>
              </p>
              <p>90&nbsp;zł</p>
            </div>
          </div>
        </div>
        <p className="pricelist-info">
          <Trans i18nKey="pricelistinfo2">Podane ceny są cenami umownymi i w przypadku większych zleceń lub
          nawiązania stałej współpracy podlegają negocjacji.</Trans>
        </p>
      </div>
    </div>
  );
}

export default Pricelist;
